<template>
    <div v-if="!$auth.loading">
      <div id="layout-wrapper" v-if="$auth.isAuthenticated">
        <NavBar />
        <SideBar
          :is-condensed="isMenuCondensed"
          :type="leftSidebarType"
          :width="layoutWidth"
          v-on:itemSelected="handleItemSelected"
          v-on:changelogSelected="handleChangelogSelected" />
        <div class="main-content">
          <div class="page-content">
            <!-- Start Content-->
            <div class="container-fluid">
  
              <!-- start page title -->
              <div class="row">
                  <div class="col-12">
                      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                          <h4 class="mt-0 mb-sm-0 font-size-18">{{ pageTitle }}</h4>
                          <div ref="breadcrumb" class="page-title-right d-none" v-show="currentPanel != 'viewXml'">
                              <ol class="breadcrumb m-0">
                                  <li class="breadcrumb-item">{{ breadcrumb.category }}</li>
                                  <li class="breadcrumb-item xmllink" v-on:click.prevent="showModal($event)" title="View Item XML">{{ breadcrumb.text }}</li>
                              </ol>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- end page title -->
              <div ref="viewXml" v-show="currentPanel == 'viewXml'">
                <h4>Item XML</h4>
                <textarea class="textarea"
                  ref="textarea"
                  placeholder="Paste XML here"
                  v-model="response"
                  autocapitalize="none"
                  spellcheck="false"
                  maxlength="maxLength"
                  @input="handleInput"
                />
                <ContentXmlView v-bind:identifier="selectedIdentifier"/>
              </div>
  
              <div v-show="currentPanel == 'item' || currentPanel == 'viewXml'" class="card controller-outcome-container response-card">
                <div class="display-response">
                  <a class="response" href="#" @click="getEndAttemptState">Get Responses: </a>
                  <span class="initial-response">[ Click Get Responses ]</span>
                  <div class="variable-response">
                    <span class="response-data"> 
                      <div v-for="response of responses" :key="response.identifier">
                        &nbsp;Variable {{response.identifier}} = <span >[{{response.value}}]</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="display-response">
                  <a class="score" href="#" @click="getEndAttemptState">Score Responses: &nbsp;</a>
                  <div class="initial-response">[ Click Score Responses ]</div>
                  <div class="score-data">
                    <div>
                      Score = {{score}}
                    </div>
                    <div>
                      Max Score = {{maxScore}}
                    </div>  
                  </div>                
                </div>
              </div>
              <div ref="itemcanvas" v-show="currentPanel == 'item' || currentPanel == 'viewXml'" class="card controller-item-container">
                <ContentView ref="contentcanvas" v-bind:identifier="selectedIdentifier" @endAttemptComplete="handleEndAttemptComplete" @resetResponses="onResetResponses"/>
              </div>
  
              <div ref="changelog" v-show="currentPanel == 'changelog'" class="card changelog-container raised">
                <div class="card-body">
                  <div class="p-lg-1">
  
                    <h4 class="mb-3">
                      <span class="text-primary">v0.4.3</span>
                      <!--small class="text-muted font-size-14"> - 31 May 2022 </small-->
                    </h4>
                    <ul>
                      <li>Include @amp-up-io/amp-qti3-item-player@0.3.1 component</li>
                      <li>Add QtiRubricBlock support</li>
                      <li>Add QtiOrderInteraction support</li>
                      <li>Update ItemFactory and Menu with Order interaction items</li>
                      <li>Draft 0.2.6 of <a href="https://docs.google.com/document/d/1KCImUAk-2uBqSOHRO1lJa1orrzF4b8cl/edit?usp=sharing&ouid=110530207701868607426&rtpof=true&sd=true" target="_blank">QTI 3 Player Reference</a></li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.4.2</span>
                      <small class="text-muted font-size-14"> - 6 April 2022 </small>
                    </h4>
                    <ul>
                      <li>Include @amp-up-io/amp-qti3-item-player@0.3.0 component</li>
                      <li>Add Catalog support</li>
                      <li>Update PnpFactory with Keyword Translation languages</li>
                      <li>Update PnpFactory with ext:sbac-glossary-illustration</li>
                      <li>Update ItemFactory and Menu with Catalog items</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.4.1</span>
                      <small class="text-muted font-size-14"> - 11 March 2022 </small>
                    </h4>
                    <ul>
                      <li>Include @amp-up-io/amp-qti3-item-player@0.1.7 component</li>
                      <li>Change registry of @amp-up-io/amp-qti3-item-player to Github Packages</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.4.0</span>
                      <small class="text-muted font-size-14"> - 10 March 2022 </small>
                    </h4>
                    <ul>
                      <li>Include @amp-up-io/amp-qti3-item-player@0.1.6 component</li>
                      <li>Remove all remnants of original PoC QtiAssessmentItem</li>
                      <li>Add new ItemFactory, PnpFactory, SessionControlFactory</li>
                      <li>Add new TestController stubs to mimic prod behaviors</li>
                      <li>Add QtiExtendedTextInteraction support</li>
                      <li>Add all new Qti Shared CSS</li>
                      <li>Draft 0.2.2 of <a href="https://docs.google.com/document/d/1KCImUAk-2uBqSOHRO1lJa1orrzF4b8cl/edit?usp=sharing&ouid=110530207701868607426&rtpof=true&sd=true" target="_blank">QTI 3 Player Reference</a></li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.3.1</span>
                      <small class="text-muted font-size-14"> - 4 January 2022 </small>
                    </h4>
                    <ul>
                      <li>Fix CDATA in qti-inline-choice issue</li>
                      <li>Add sample qti-inline-choice item with CDATA and Numeric Entities</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.3.0</span>
                      <small class="text-muted font-size-14"> - 17 December 2021 </small>
                    </h4>
                    <ul>
                      <li>Implement QtiSetDefaultValue processing rule</li>
                      <li>Fix QtiTemplateConstraint bug</li>
                      <li>Fix "4" key on some EE keyboard palettes - remove LaTeX style</li>
                      <li>Add Text Entry Interaction item examples to menu</li>
                      <li>Add template math-variable MS demonstration item</li>
                      <li>Draft 0.2.1 of <a href="https://docs.google.com/document/d/1KCImUAk-2uBqSOHRO1lJa1orrzF4b8cl/edit?usp=sharing&ouid=110530207701868607426&rtpof=true&sd=true" target="_blank">QTI 3 Player Reference</a></li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.2.0</span>
                      <small class="text-muted font-size-14"> - 5 December 2021 </small>
                    </h4>
                    <ul>
                      <li>Implement qti-field-value expression</li>
                      <li>Implement record cardinality on all variable types</li>
                      <li>Improved definition of QTI_CONTEXT</li>
                      <li>Stub out built-in "duration" response variable</li>
                      <li>Implement Modal QTI 3 XML viewer</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.1.5</span>
                      <small class="text-muted font-size-14"> - 2 December 2021 </small>
                    </h4>
                    <ul>
                      <li>Implement qti-custom-operator class="amp:ee.isEquivalent" component</li>
                      <li>Add item for EE w/qti-custom-operator class="amp:ee.isEquivalent" scoring</li>
                      <li>Improve custom operator Unsupported handling</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.1.4</span>
                      <small class="text-muted font-size-14"> - 29 November 2021 </small>
                    </h4>
                    <ul>
                      <li>Add 2 EE Keyboard Palettes</li>
                      <li>Four pallete configs: "numeric-basic", "numeric-fraction-basic", "numeric-operators-basic", "all-palettes"</li>
                      <li>Fix Restrict Keys bug on Numeric Operators</li>
                      <li>Add Custom Operator definition atttribute parsing</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.1.3</span>
                      <small class="text-muted font-size-14"> - 23 November 2021 </small>
                    </h4>
                    <ul>
                      <li>Fix QtiDelete bug</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.1.2</span>
                      <small class="text-muted font-size-14"> - 22 November 2021 </small>
                    </h4>
                    <ul>
                      <li>Implement QtiCustomInteraction class="amp:equation-editor"</li>
                      <li>Add 2 built-in keypads: numeric and basic operators</li>
                      <li>Implemented EE restrictKeys="true|false"</li>
                      <li>Implemented EE w/keypad display "onfocus"</li>
                      <li>Added two items to demonstrate the EE w/keypads and onfocus</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.1.1</span>
                      <small class="text-muted font-size-14"> - 16 November 2021 </small>
                    </h4>
                    <ul>
                      <li>Add "Solve This"</li>
                      <li>Fix bug in QtiChoiceInteraction reset</li>
                      <li>Modal Feedback styling</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.1.0</span>
                      <small class="text-muted font-size-14"> - 15 November 2021 </small>
                    </h4>
                    <ul>
                      <li>Milestone Release of QTI3 Player SDK v0.1.0</li>
                      <li>New implementation of QtiInlineChoiceInteraction and shuffle</li>
                      <li>Add 5 Items to demonstrate QtiInlineChoice</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.0.9</span>
                      <small class="text-muted font-size-14"> - 14 November 2021 </small>
                    </h4>
                    <ul>
                      <li>New implementation of QtiChoiceInteraction and shuffle</li>
                      <li>Add 2 Items to demonstrate MC/MS qti-labels-none</li>
                      <li>Add Monty Hall (Take 2) adaptive example</li>
                      <li>Refactor QtiFeedbackBlock and QtiFeedbackInline to fix showHide</li>
                      <li>Fix bugs in template variable and outcome variable reset</li>
                      <li>Add "Show Example" example (amp-08c-nextgen)</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.0.8</span>
                      <small class="text-muted font-size-14"> - 11 November 2021 </small>
                    </h4>
                    <ul>
                      <li>New implementation of QtiModalFeedback</li>
                      <li>ModalFeedback component</li>
                      <li>Fix QtiPrintedVariable sequence bug.  Now run at end of RP, but before evaluateFeedbacks</li>
                      <li>Fix template math variable bug in QtiModalFeedback</li>
                      <li>Add format printf capability to QtiPrintedVariable</li>
                      <li>QtiRandomFloat implementation</li>
                      <li>Add Sales Tax example (amp-08a-nextgen)</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.0.7</span>
                      <small class="text-muted font-size-14"> - 9 November 2021 </small>
                    </h4>
                    <ul>
                      <li>New implementation of template math variables</li>
                      <li>Add 2nd Adaptive example</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.0.6</span>
                      <small class="text-muted font-size-14"> - 4 November 2021 </small>
                    </h4>
                    <ul>
                      <li>Tune QtiCustomInteraction framework implementation</li>
                      <li>Tweak CSS for QtiInlineChoiceInteraction</li>
                      <li>Add Adaptive example</li>
                      <li>Add Template (image) example</li>
                      <li>Add 3 MC and 3 MS examples</li>
                      <li>Fix bug with Template reset</li>
                    </ul>
  
                    <h4 class="mb-3">
                      <span class="text-primary">v0.0.5</span>
                      <small class="text-muted font-size-14"> - 2 November 2021 </small>
                    </h4>
                    <ul>
                      <li>QtiCustomOperator framework implementation</li>
                      <li>QtiCustomOperator class="math.stringToFloat" implementation</li>
                      <li>QtiDefault implementation</li>
                      <li>QtiAnyN implementation</li>
                      <li>QtiPower implementation</li>
                      <li>QtiPatternMatch implementation</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.0.4</span>
                      <small class="text-muted font-size-14"> - 30 October 2021 </small>
                    </h4>
                    <ul>
                      <li>QtiIntegerModulus, QtiIntegerToFloat, QtiSubtract implementation</li>
                      <li>QtiIndex implementation</li>
                      <li>QtiGcd, QtiLcm implementation</li>
                      <li>QtiSubstring, QtiStringMatch implementation</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.0.3</span>
                      <small class="text-muted font-size-14"> - 28 October 2021 </small>
                    </h4>
                    <ul>
                      <li>Process Template MathVariables</li>
                      <li>QtiStatsOperator implementation</li>
                      <li>QtiOrdered implementation</li>
                      <li>QtiMin, QtiMax implementation</li>
                      <li>QtiTruncate, QtiRound implementation</li>
                      <li>QtiRepeat implementation</li>
                      <li>QtiTemplateBlock implementation</li>
                      <li>Refactor $store.  Modularize assessmentitem.</li>
                      <li>Initial commits for qti-stylesheet, qti-companion-materials</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.0.2</span>
                      <small class="text-muted font-size-14"> - 26 October 2021 </small>
                    </h4>
                    <ul>
                      <li>Custom mxl prompt and answer styling</li>
                      <li>QtiMathOperator implementation</li>
                      <li>QtiMathConstant implementation</li>
                      <li>QtiRoundTo implementation</li>
                      <li>QtiGt, QtiGte, QtiLt, QtiLte implementation</li>
                      <li>QtiDivide implementation</li>
                      <li>QtiEqualRounded implementation</li>
                      <li>QtiTemplateConstraint implementation</li>
                    </ul>
                    <h4 class="mb-3">
                      <span class="text-primary">v0.0.1</span>
                      <small class="text-muted font-size-14"> - 11 October 2021 </small>
                    </h4>
                    <ul>
                      <li>Project Initiated</li>
                    </ul>
  
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import NavBar from '@/shared/components/nav-bar'
  import SideBar from '@/shared/components/side-bar'
  import ContentView from '@/shared/components/ContentView.vue'
  import ContentXmlView from '@/shared/components/ContentXmlView.vue'
  
  export default {
    name: 'testharness',
    components: {
      NavBar,
      SideBar,
      ContentView,
      ContentXmlView
    },
  
    data() {
      return {
        pageTitle: 'QTI 3 Player',
        currentPanel: 'item',
        selectedIdentifier: '',
        isMenuCondensed: false,
        breadcrumb: {
          category: '',
          text: ''
        },
        response: '',
        score: 0,
        maxScore: 0,
        responses: []
      }
    },
  
    computed: {
      layoutType: function () {
        return 'vertical'
      },
      layoutWidth: function () {
        return 'fluid'
      },
      leftSidebarType: function () {
        return 'dark'
      },
      topbar: function () {
        return 'light'
      },
      loader: function () {
        return false
      }
    },
  
    methods: {

      onResetResponses() {
        this.$el.querySelector('.score-data').style.display = 'none';
        this.$el.querySelector('.variable-response').style.display = 'none';
        this.$el.querySelectorAll('.initial-response').forEach(initialResponse => {
          initialResponse.style.display = 'block';
        })
      },

      handleEndAttemptComplete(event) {
        this.endAttemptState = event;
      },

      getScore() {
        this.score = (this.endAttemptState.state.outcomeVariables.find((item) => item.identifier === 'SCORE')?.value) || 0;
        this.maxScore = (this.endAttemptState.state.outcomeVariables.find((item) => item.identifier === 'MAXSCORE')?.value) || 0;
      },

      getCurrentResponse() {
        this.responses = []
        for (const obj of this.endAttemptState.state.responseVariables) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty('correctResponse')) {
              this.responses.push(obj);
            }
        }
      },

      getEndAttemptState(event) {
        event.target.nextSibling.style.display = 'none';
        event.target.classList.contains('score') ? this.$el.querySelector('.score-data').style.display = 'block' : '';
        event.target.classList.contains('response') ? this.$el.querySelector('.variable-response').style.display = 'block' : '';
        this.$refs.contentcanvas.qti3Player.endAttempt('navigateNextItem');
        this.getScore();
        this.getCurrentResponse();
      },
  
      showModal () {
        this.$bvModal.show('modal-xml')
      },
  
      toggleMenu () {
        document.body.classList.toggle('sidebar-enable')
        document.body.classList.toggle('vertical-collpsed')
        this.isMenuCondensed = !this.isMenuCondensed
      },
  
      /**
       * Handler to switch item identifier
       * @param - identifier (String) - item identifier
       */
  
      handleItemSelected (identifier) {
        if (identifier == 'view-xml') {
           this.currentPanel = 'viewXml'
           this.selectedIdentifier = ''
           this.response = ''
           this.breadcrumb.text = 'viewXml'
        }
        else {
        this.pageTitle = 'QTI 3 Item Player'
        // this.$refs.changelog.classList.add('d-none')
        this.selectedIdentifier = identifier
        this.breadcrumb.category = 'Items'
        this.breadcrumb.text = identifier
        this.currentPanel = 'item'
        this.$refs.breadcrumb.classList.remove('d-none')
        }
      },
  
      handleChangelogSelected () {
        this.pageTitle = 'Changelog'
        this.currentPanel = 'changelog'
        this.$refs.breadcrumb.classList.add('d-none')
      },
  
      handleInput() {
        this.selectedIdentifier = this.response;
      },
      logout() {
        this.$auth.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      }
    },
  
    created () {
      this.pageTitle = ''
      document.body.removeAttribute('data-layout', 'horizontal')
      document.body.removeAttribute('data-topbar', 'dark')
      document.body.removeAttribute('data-layout-size', 'boxed')
    },
  
    mounted () {
    }
  }
  </script>
  
  <style>
  .controller-item-container {
    min-height: 475px;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
  }
  .card.raised {
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
  }
  .scrollable {
    overflow-y: auto;
    max-height: 300px;
  }
  .breadcrumb-item.xmllink {
    cursor: pointer;
    text-decoration: none !important;
    color: #3d8ef8;
  }
  .textarea {
    width: 700px;
    height: 235px;
  }
  .table {
    width: auto;
  }
  .response-card {
    padding: 15px;
  }
  .display-response {
    display: flex;
  }
  .variable-response, .score-data {
    display: none;
  }
  </style>
  